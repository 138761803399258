.sat-setting {
    width: 100%;
    background-color: #fbfbfb;
    border: 1px solid #d9d9d9;
    padding: 10px 10px 0px 5px;
    text-align: left;
}

.show-nearby {
    display: flex;
    justify-content: center;
}

